export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none">
      <path
        d="M10.2014 17.3977C14.1772 17.3977 17.4002 14.1746 17.4002 10.1988C17.4002 6.22303 14.1772 3 10.2014 3C6.22559 3 3.00256 6.22303 3.00256 10.1988C3.00256 14.1746 6.22559 17.3977 10.2014 17.3977Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5444 17.9496C16.9684 19.2294 17.9362 19.3574 18.6801 18.2375C19.36 17.2137 18.912 16.3738 17.6802 16.3738C16.7684 16.3658 16.2565 17.0777 16.5444 17.9496Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none">
      <path
        d="M7.14392 13V1"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 7H1"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-right">
      <polyline points="9 18 15 12 9 6" />
    </svg>
  );
};
export const ArrowLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-left">
      <polyline points="15 18 9 12 15 6" />
    </svg>
  );
};
export const ThreeDotIcon = () => {
  return (
    <svg
      width="24"
      height="6"
      viewBox="0 0 24 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="3" cy="3" r="2.5" fill="#333333" />
      <circle cx="12" cy="3" r="2.5" fill="#333333" />
      <circle cx="21" cy="3" r="2.5" fill="#333333" />
    </svg>
  );
};
export const CartIcon = () => {
  return (
    <svg
      className=""
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.09 6.97978C20.24 6.03978 18.82 5.56978 16.76 5.56978H16.52V5.52978C16.52 3.84978 16.52 1.76978 12.76 1.76978H11.24C7.48004 1.76978 7.48004 3.85978 7.48004 5.52978V5.57978H7.24004C5.17004 5.57978 3.76004 6.04978 2.91004 6.98978C1.92004 8.08978 1.95004 9.56977 2.05004 10.5798L2.06004 10.6498L2.13749 11.4631C2.15176 11.6128 2.23242 11.7481 2.35831 11.8305C2.59812 11.9874 2.99946 12.2461 3.24004 12.3798C3.38004 12.4698 3.53004 12.5498 3.68004 12.6298C5.39004 13.5698 7.27004 14.1998 9.18004 14.5098C9.27004 15.4498 9.68004 16.5498 11.87 16.5498C14.06 16.5498 14.49 15.4598 14.56 14.4898C16.6 14.1598 18.57 13.4498 20.35 12.4098C20.41 12.3798 20.45 12.3498 20.5 12.3198C20.8968 12.0955 21.3083 11.8193 21.6835 11.5486C21.7965 11.4671 21.8688 11.341 21.8842 11.2025L21.9 11.0598L21.95 10.5898C21.96 10.5298 21.96 10.4798 21.97 10.4098C22.05 9.39977 22.03 8.01978 21.09 6.97978ZM13.09 13.8298C13.09 14.8898 13.09 15.0498 11.86 15.0498C10.63 15.0498 10.63 14.8598 10.63 13.8398V12.5798H13.09V13.8298ZM8.91004 5.56978V5.52978C8.91004 3.82978 8.91004 3.19978 11.24 3.19978H12.76C15.09 3.19978 15.09 3.83978 15.09 5.52978V5.57978H8.91004V5.56978Z"
        fill="#0D0D10"
      />
      <path
        d="M20.8733 13.7344C21.2269 13.5661 21.6342 13.8464 21.5988 14.2364L21.2398 18.1902C21.0298 20.1902 20.2098 22.2302 15.8098 22.2302H8.18984C3.78984 22.2302 2.96984 20.1902 2.75984 18.2002L2.41913 14.4524C2.38409 14.0669 2.78205 13.787 3.13468 13.9465C4.2741 14.462 6.37724 15.3766 7.67641 15.7169C7.84072 15.7599 7.97361 15.8775 8.04556 16.0314C8.65253 17.3295 9.96896 18.0202 11.8698 18.0202C13.752 18.0202 15.085 17.3029 15.694 16.0016C15.766 15.8476 15.8991 15.7301 16.0635 15.6868C17.443 15.3238 19.6816 14.3015 20.8733 13.7344Z"
        fill="#0D0D10"
      />
    </svg>
  );
};
export const ClockIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44 23.9999C44 35.0399 35.04 43.9999 24 43.9999C12.96 43.9999 4 35.0399 4 23.9999C4 12.9599 12.96 3.99988 24 3.99988C35.04 3.99988 44 12.9599 44 23.9999Z"
        stroke="#555555"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4198 30.3597L25.2198 26.6597C24.1398 26.0197 23.2598 24.4797 23.2598 23.2197V15.0197"
        stroke="#555555"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
